<template>
  <Details
    title="shared data key"
    resource="dataKeys"
    :base-path="basePath"
    data-provider="$raaDataProvider"
    :title-template-render="renderTitle"
    :breadcrumb-template-render="renderBreadcrumbs"
  >
    <template v-slot:side-panel="{ record }">
      <DetailsSidePanel entity-title="shared data key" :view-mode="Boolean(record.deletedAt)"></DetailsSidePanel>
    </template>
    <template v-slot:default="detailsProps">
      <div>
        <div v-if="detailsProps.record.deletedAt" class="flex justify-end text-sm mb-4">
          {{detailsProps.getDeletedLabel(detailsProps.record.deletedAt)}}
        </div>
      </div>
      <shared-data-key-form
        :initialValues="detailsProps.record"
        :on-submit="detailsProps.handleSubmit"
        :loading="detailsProps.loading"
        @close="detailsProps.redirectToList"
      />
    </template>
  </Details>
</template>

<script>
  import ModalNavigation from "@/mixins/ModalNavigation";
  import Details from "@/components/auth/details/Details";
  import DetailsSidePanel from "@/components/auth/details/DetailsSidePanel";
  import SharedDataKeyForm from "@/views/auth/raa-devtools/shared-data-keys/SharedDataKeyForm";

  export default {
    name: "SharedDataKeyEdit",
    components: {
      SharedDataKeyForm,
      Details,
      DetailsSidePanel
    },
    mixins: [ModalNavigation],
    computed: {
      basePath: function () {
        return this.$route.name.replace('edit', 'index');
      }
    },
    methods:{
      renderBreadcrumbs({ deletedAt }) {
        return deletedAt ? 'view' : 'edit';
      },
      renderTitle({ key, deletedAt }) {
        return `${deletedAt ? 'view' : 'edit'} shared data key ${key}`;
      }
    },
  }
</script>

<style scoped>

</style>
